<template>
    <v-dialog
        v-model="show"
        max-width="500px"
        v-on:click:outside="cancelMethod"
    >
        <v-card>
            <v-toolbar
                    color="primary"
                    dark
                    class="text-h5"
                >{{formTitle}}
                    <v-spacer></v-spacer>
                    <v-btn 
                        icon
                        @click="cancelMethod"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
            </v-toolbar>

        <v-card-text>
            <v-form ref="form">
            <v-container class="mt-4">
                <v-row>
                    <v-col>
                        <v-text-field

                        v-model="updatedUser.username"
                        label="Nome Utente"
                        :rules="[rules.required, rules.counter]"
                        required
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field
                        v-model="updatedUser.firstName"
                        label="Nome"
                        ></v-text-field>
                    </v-col>
                    <v-col>
                        <v-text-field
                        v-model="updatedUser.lastName"
                        label="Cognome"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field
                        v-model="updatedUser.email"
                        label="E-Mail"
                        :rules="[rules.required, rules.email]"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-autocomplete
                            :items="companyNames"
                            v-model="updatedCompanyName"
                            item-text="companyName"
                            label="Azienda"
                            :rules="[rules.required]"
                        ></v-autocomplete>
                    </v-col>
                    <v-col>
                        <v-autocomplete
                            :items="departmentNames"
                            v-model="updatedDepartment.departmentName"
                            item-text="departmentName"
                            label="Reparto"
                            clearable
                        ></v-autocomplete>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-autocomplete
                            :items="roleNames"
                            v-model="updatedUser.role"
                            label="Permesso"
                            :rules="[rules.required]"
                            item-text="label"
                            item-value="value"
                            v-if="updatedUser.role != 'SYSTEM'"
                            :prepend-inner-icon="userRoles[updatedUser.role] ? userRoles[updatedUser.role].icon : undefined"
                        >
                            <template v-slot:item="data">
                                <!-- <template v-if="typeof data.item !== 'object'">
                                <v-list-item-content v-text="data.item"></v-list-item-content>
                                </template>  -->
                                <template>
                                <v-list-item-avatar>
                                    <v-icon>{{userRoles[data.item.value].icon}}</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title v-html="data.item.label"></v-list-item-title>
                                </v-list-item-content>
                                </template>
                            </template>
                        </v-autocomplete>
                    </v-col>
                    <v-col>
                        <v-checkbox
                            v-model="updatedUser.includeInReports"
                            label="Includi nei report"
                        ></v-checkbox>
                    </v-col>
                </v-row>
            </v-container>
            </v-form>
        </v-card-text>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                v-if="!edit"
                color="green accent-2"
                class="px-4"
                @click="createMethod"
            >
                <v-icon left>
                    mdi-check
                </v-icon>
                Salva
            </v-btn>
            <v-btn
                v-else
                color="amber lighten-1"
                class="px-4"
                @click="updateMethod"
            >
                <v-icon left>
                    mdi-check
                </v-icon>
                Modifica
            </v-btn>
        </v-card-actions>
        </v-card>
    </v-dialog>

</template>
<script>
import { mapState } from 'vuex'

export default {
   data: function() {
       return {
            rules: {
                required: value => !!value || 'Obbligatorio',
                counter: value => (value || '').length >= 8 || 'Minimo 8 caratteri',
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || 'E-mail non valida'
                },
            },
            updatedUser: {
                username: null,
                email: null,
                firstName: null,
                lastName: null,
                role: null,
                includeInReports: false
            },
            updatedCompanyName: null,
            updatedDepartment: {}
        }
   },
   props: {
       edit: {
           type: Boolean,
           default: true
       },
       editedUser: {
           type: Object,
           default: function() {
               return {
                   company: {},
               }
           }
       },
       show: {
           type: Boolean,
           required: true
       }
   },
   watch: {
       editedUser: {
            handler: function(val) {
                let v = val || {};
                this.updatedUser.username = v.username;
                this.updatedUser.email = v.email;
                this.updatedUser.firstName = v.firstName;
                this.updatedUser.lastName = v.lastName;
                this.updatedUser.includeInReports = v.includeInReports;
                this.updatedUser.role = v.role;
                this.updatedCompanyName = (v.company || {}).companyName;
                this.updatedDepartment = v.department || {};
            },
            deep: true,
       }
   },
   methods: {
       cancelMethod: function() {
           this.$refs.form.reset();
            this.$emit('dialog-close');
       },
       createMethod: function() {
            if (this.$refs.form.validate()) {
                if(this.updatedDepartment && this.updatedDepartment.departmentName)
                    this.updatedUser.department = this.updatedDepartment;
                else
                    this.updatedUser.department = null;
                this.$emit('create-user', this.updatedUser, this.updatedCompanyName);
            }
       },
       updateMethod: function() {
            if (this.$refs.form.validate()) {
                if(this.updatedDepartment && this.updatedDepartment.departmentName)
                    this.updatedUser.department = this.updatedDepartment;
                else
                    this.updatedUser.department = null;
                this.$emit('update-user', this.editedUser.username, this.updatedUser, this.updatedCompanyName);
            }  
       }
   },
   computed: {
        ...mapState({
            companies: state => state.dataStorage.companies,
            departments: state => state.dataStorage.departments,
            userRoles: state => state.dataStorage.userRoles
        }),
       formTitle: function() {
           return this.edit ? 'Modifica Utente' : 'Crea Utente';
       },
       companyNames: function() {
           return this.companies.map(c => c.companyName);
       },
       departmentNames: function() {
            return this.departments.map(d => d.departmentName);
       },
       roleNames: function() {
           return Object.keys(this.userRoles).filter(role => role != 'SYSTEM').map(role => {
               return {value: role, label: this.userRoles[role].label}
           });
       },
    //    updatedDepartmentName: {
    //     get: function() {
    //         console.log(this.updatedUser, this.updatedUser.department)
    //         return (this.updatedUser.department || {}).departmentName;
    //     },
    //     set: function(val) {
    //         if(val) {
    //             this.updatedUser.department = {departmentName : val};
    //         } else {
    //             this.updatedUser.department = null;
    //         }
    //     }
    //    }
   }
}
</script>